import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import imgHero from "../assets/image/conference/png/hero-main.png";
import imgC1 from "../assets/image/conference/jpg/content-1-img.jpg";
import imgCR from "../assets/image/conference/png/content-1-round.png";
import Content4 from "../sections/landing6/Content3";

const Hero = () => {
  return (
    <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
      {/* <!-- Hero Area --> */}
      <div className="bg-default-6 mt-28 position-relative">
        <div
          className="hero-area bg-image bg-blue  dark-mode-texts mx-6 mx-lg-9 rounded-15"
          css={`
            background-image: url(https://shadepro-gatsby.netlify.app/static/pattern-4-8263160699f4d9a09419f6536a57e66a.png);
          `}
        >
          <Container>
            <Row className="justify-content-sm-end">
              <Col lg="6" md="8">
                <div className="hero-content pe-xl-16 pt-18 pt-lg-31 pb-md-18 pb-lg-30 mb-9 mb-md-0">
                  <h1 className="title gr-text-2 mb-8 heading-color">AI &amp; Machine Learning</h1>
                  <p className="gr-text-8 mb-0 text-color-opacity">
                  We harness the power of artificial intelligence and machine learning to transform businesses
                  and drive innovation
                  </p>
                  <Button href="/contact" variant="success" className=" mt-11 btn btn-success">
                    Contact us
                  </Button>
                </div>
              </Col>
              <Col
                lg="5"
                md="4"
                sm="7"
                className="offset-lg-1 align-self-sm-end position-relative">
                <div
                  className="hero-img hero-img-custom z-index-1 mb-n12 mb-md-0"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="500"
              >
                </div>
              </Col>
            </Row>
          </Container>
        </div>
          </div>
        <div className="content-section pt-13 pt-lg-29 pb-12 pb-lg-20 bg-default-6">
        <Container>
          <Row className="align-items-center ">
            <Col className="ps-lg-15">
              <div className="section-title content-text mb-13 mb-lg-15">
                <h2 className="title gr-text-4 mb-6 heading-color">Our Core Offerings</h2>
                <p className="gr-text-8 pe-lg-12 mb-0 text-color-opacity">
                Our tailored solutions empower organizations to leverage data-driven insights, automate
                processes, and unlock new opportunities for growth and efficiency.
                </p>
              </div>
              <div className="content-widget">             
                <Row className="mb-n11">
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="750"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        1. Custom AI Solutions
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      We develop custom AI solutions tailored to your specific business needs. Whether it&#39;s
                    natural language processing, computer vision, predictive analytics, or recommendation
                    systems, we design AI models that deliver actionable insights and business value.
                         </p>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        2. Machine Learning Development
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Our team of data scientists and engineers specializes in developing machine learning
                    models to solve complex problems. From data preprocessing and model training to
                    deployment and monitoring, we handle every aspect of the ML lifecycle.
                      </p>
                    </div>
                </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        3. Machine Learning Development
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Automate repetitive tasks and optimize workflows using AI-driven automation solutions.
                    We help you streamline operations, improve productivity, and reduce costs by leveraging
                    intelligent automation technologies.
                      </p>
                    </div>
                  </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        4. Predictive Analytics
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Leverage predictive modeling to anticipate trends, forecast outcomes, and make data-
                    driven decisions. Our predictive analytics solutions enable proactive decision-making
                    and empower businesses to stay ahead of the competition.
                      </p>
                    </div>
                  </Col>
                 <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        5. AI Consulting and Strategy
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Gain strategic insights and guidance from our AI experts. We offer consulting services
                    to help you define your AI roadmap, identify use cases, assess data readiness, and
                    implement AI initiatives effectively.
                      </p>
                    </div>
                  </Col>               
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
    </div>
    <Content4 />
    </PageWrapper>
  );
};

export default Hero;